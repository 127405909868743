import {Injectable} from '@angular/core';
import {AccountSettingsService} from '../../api/services/account-settings.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {AccountSettingsWindowComponent} from './account-settings-window/account-settings-window.component';
import {NbWindowService} from '@nebular/theme';


@Injectable({providedIn: 'root'})
export class AccountSettingsWindowService {

  private readonly _accountSettingsCreated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(accountSettingsService: AccountSettingsService,
              private windowsService: NbWindowService) {
    accountSettingsService.accountSettingsGet$Response().subscribe(
      response => {
        if (response.status === 204) {
          // Account settings are not available yet
          this._accountSettingsCreated$.next(false);
        } else {
          this._accountSettingsCreated$.next(true);
        }
      },
    );
  }

  public accountSettingsCreated$(): Observable<boolean> {
    return this._accountSettingsCreated$;
  }

  public openAccountSettingsWindow() {
    this.windowsService.open(AccountSettingsWindowComponent, {
      title: 'Account Settings',
      buttons: {
        minimize: false,
      },
      closeOnBackdropClick: false,
      context: {
        onAccountSettingsUpdated: () => this.onAccountSettingsUpdated(),
      },
    });
  }

  private onAccountSettingsUpdated() {
    this._accountSettingsCreated$.next(true);
  }

}
