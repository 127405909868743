import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'timing'})
export class TimingPipe implements PipeTransform {
  transform(time: number, groups?: 2 | 3): string {
    const requestedGroups = groups || 2;

    if (time) {
      if (requestedGroups === 2) {
        const largeGroup = Math.floor(time / 60);
        const smallGroup = Math.floor(time % 60);
        return `${this.twoDigit(largeGroup)}:${this.twoDigit(smallGroup)}`;
      } else {
        const largestGroup = Math.floor(time / 60 / 60);
        const largeGroup = Math.floor(time / 60 % 60);
        const smallGroup = Math.floor(time % 60);
        return `${this.twoDigit(largestGroup)}:${this.twoDigit(largeGroup)}:${this.twoDigit(smallGroup)}`;
      }
    }

    return requestedGroups === 2 ? '00:00' : '00:00:00';
  }

  private twoDigit(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }
}
