import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {NbButtonModule, NbCardModule, NbSpinnerModule} from '@nebular/theme';



@NgModule({
  declarations: [
    ConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    NbButtonModule,
    NbCardModule,
    NbSpinnerModule,
  ],
})
export class DialogsModule { }
