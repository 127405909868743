/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Client } from '../../models/client';
import { ClientUpdateRequest } from '../../models/client-update-request';

export interface ClientsClientIdPut$Params {

/**
 * ID of the Client to update
 */
  clientId: string;
      body: ClientUpdateRequest
}

export function clientsClientIdPut(http: HttpClient, rootUrl: string, params: ClientsClientIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<Client>> {
  const rb = new RequestBuilder(rootUrl, clientsClientIdPut.PATH, 'put');
  if (params) {
    rb.path('clientId', params.clientId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Client>;
    })
  );
}

clientsClientIdPut.PATH = '/clients/{clientId}';
