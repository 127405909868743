import { Component } from '@angular/core';

// Import the AuthService type from the SDK
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'ngx-app-logout-button',
  template: '<button (click)="auth.logout()">Log out</button>',
  standalone: true,
})
export class LogoutButtonComponent {
  // Inject the authentication service into your component through the constructor
  constructor(public auth: AuthService) {}
}
