/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountSettings } from '../models/account-settings';
import { accountSettingsGet } from '../fn/account-settings/account-settings-get';
import { AccountSettingsGet$Params } from '../fn/account-settings/account-settings-get';
import { accountSettingsPut } from '../fn/account-settings/account-settings-put';
import { AccountSettingsPut$Params } from '../fn/account-settings/account-settings-put';

@Injectable({ providedIn: 'root' })
export class AccountSettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountSettingsGet()` */
  static readonly AccountSettingsGetPath = '/account-settings';

  /**
   * Retrieve Account Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGet$Response(params?: AccountSettingsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountSettings>> {
    return accountSettingsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve Account Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGet(params?: AccountSettingsGet$Params, context?: HttpContext): Observable<AccountSettings> {
    return this.accountSettingsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettings>): AccountSettings => r.body)
    );
  }

  /** Path part for operation `accountSettingsPut()` */
  static readonly AccountSettingsPutPath = '/account-settings';

  /**
   * Updated Account Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsPut$Response(params: AccountSettingsPut$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountSettings>> {
    return accountSettingsPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Updated Account Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsPut(params: AccountSettingsPut$Params, context?: HttpContext): Observable<AccountSettings> {
    return this.accountSettingsPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettings>): AccountSettings => r.body)
    );
  }

}
