<div class="header-container" xmlns="http://www.w3.org/1999/html">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <div >
        <div class="float-left">
          <img class="logo-img" src="assets/images/logo.png">
        </div>
        <div class="logo-title">
          <span>Simple Freelancer</span>
          <br/>
          <span class="logo-subtitle">Freelancing made simple</span>
        </div>
      </div>
    </a>
  </div>
</div>

<div class="header-container">
  <button nbButton class="theme-toggle" (click)="onDarkModeToggled()" ghost>
    <nb-icon icon="{{darkMode ? 'sun-outline' : 'moon-outline'}}"/>
  </button>


  <nb-user size='medium'
           name="{{user?.name}}"
           picture="{{user?.picture}}"
           title="{{user?.email}}"
           [nbContextMenu]="USER_MENU"
           nbContextMenuPlacement=""
           nbContextMenuTag="{{USER_MENU_TAG}}"></nb-user>
</div>
