import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AccountSettingsService} from '../../../api/services/account-settings.service';
import {AccountSettings} from '../../../api/models/account-settings';
import {NbToastrService, NbWindowRef} from '@nebular/theme';

@Component({
  selector: 'ngx-account-settings-window',
  templateUrl: './account-settings-window.component.html',
  styleUrls: ['./account-settings-window.component.scss'],
})
export class AccountSettingsWindowComponent implements OnInit {
  logoFile: string;
  submitting: boolean = false;
  loading: boolean = true;

  accountSettingsForm: FormGroup = new FormGroup({
    businessName: new FormControl(''),
    emailAddress: new FormControl(''),
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postCode: new FormControl(''),
    country: new FormControl(''),
    invoiceGracePeriod: new FormControl(''),
    taxNumber: new FormControl(''),
  });

  constructor(private accountSettingsService: AccountSettingsService,
              private toasterService: NbToastrService,
              private windowRef: NbWindowRef) {
  }

  onAccountSettingsUpdated: () => void;

  ngOnInit(): void {
    this.accountSettingsService.accountSettingsGet$Response()
      .subscribe(
        (response) => {
          if (response.status === 204) {
            return;
          }
          const accountSettings = response.body;
          this.accountSettingsForm.get('businessName').setValue(accountSettings.businessName);
          this.accountSettingsForm.get('emailAddress').setValue(accountSettings.emailAddress);
          this.accountSettingsForm.get('addressLine1').setValue(accountSettings.address.addressLine1);
          this.accountSettingsForm.get('addressLine2').setValue(accountSettings.address.addressLine2);
          this.accountSettingsForm.get('city').setValue(accountSettings.address.city);
          this.accountSettingsForm.get('postCode').setValue(accountSettings.address.postCode);
          this.accountSettingsForm.get('state').setValue(accountSettings.address.state);
          this.accountSettingsForm.get('country').setValue(accountSettings.address.country);
          this.accountSettingsForm.get('invoiceGracePeriod').setValue(accountSettings.invoiceGracePeriod);
          this.accountSettingsForm.get('taxNumber').setValue(accountSettings.taxNumber);
          this.logoFile = accountSettings.logo;
        },
        () => {
          this.toasterService.danger('', 'Failed to retrieve current account settings', {
            duration: 5000,
          });
          this.windowRef.close();
        },
        () => {
          this.loading = false;
        },
      );
  }

  protected isTouchedAndInvalid(fieldName: string) {
    const formControl = this.accountSettingsForm.get(fieldName);
    return formControl?.touched && !formControl?.valid;
  }

  protected onSubmit() {
    this.submitting = true;

    const body: AccountSettings = {
      businessName: this.accountSettingsForm.get('businessName').value,
      emailAddress: this.accountSettingsForm.get('emailAddress').value,
      invoiceGracePeriod: this.accountSettingsForm.get('invoiceGracePeriod').value,
      taxNumber: this.accountSettingsForm.get('taxNumber').value,
      logo: this.logoFile,
      address: {
        addressLine1: this.accountSettingsForm.get('addressLine1').value,
        addressLine2: this.accountSettingsForm.get('addressLine2').value,
        city: this.accountSettingsForm.get('city').value,
        country: this.accountSettingsForm.get('country').value,
        postCode: this.accountSettingsForm.get('postCode').value,
        state: this.accountSettingsForm.get('state').value,
      },
    };

    this.accountSettingsService.accountSettingsPut({body})
      .subscribe(() => {
          this.toasterService.success('', 'Account Settings updated successfully.', {
            duration: 5000,
          });
          this.windowRef.close();
          this.onAccountSettingsUpdated();
        },
        (err) => {
          this.toasterService.danger('', 'Failed to update Account Settings', {
            duration: 5000,
          });
          this.submitting = false;
        });
  }

  onFileSelected(event) {

    event.preventDefault();

    const file: File = event.target.files[0];

    if (file) {

      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        this.logoFile = fileReader.result as string;
      };

    }
  }

  closeWindow() {
    this.windowRef.close();
  }
}
