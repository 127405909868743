/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Invoice } from '../models/invoice';
import { invoicesActionsIssuePost } from '../fn/invoices/invoices-actions-issue-post';
import { InvoicesActionsIssuePost$Params } from '../fn/invoices/invoices-actions-issue-post';
import { invoicesGet } from '../fn/invoices/invoices-get';
import { InvoicesGet$Params } from '../fn/invoices/invoices-get';
import { invoicesInvoiceIdActionsGeneratePdfPost } from '../fn/invoices/invoices-invoice-id-actions-generate-pdf-post';
import { InvoicesInvoiceIdActionsGeneratePdfPost$Params } from '../fn/invoices/invoices-invoice-id-actions-generate-pdf-post';
import { invoicesInvoiceIdGet } from '../fn/invoices/invoices-invoice-id-get';
import { InvoicesInvoiceIdGet$Params } from '../fn/invoices/invoices-invoice-id-get';

@Injectable({ providedIn: 'root' })
export class InvoicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `invoicesActionsIssuePost()` */
  static readonly InvoicesActionsIssuePostPath = '/invoices/actions/issue';

  /**
   * Issue an Invoice.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesActionsIssuePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoicesActionsIssuePost$Response(params: InvoicesActionsIssuePost$Params, context?: HttpContext): Observable<StrictHttpResponse<Invoice>> {
    return invoicesActionsIssuePost(this.http, this.rootUrl, params, context);
  }

  /**
   * Issue an Invoice.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesActionsIssuePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invoicesActionsIssuePost(params: InvoicesActionsIssuePost$Params, context?: HttpContext): Observable<Invoice> {
    return this.invoicesActionsIssuePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Invoice>): Invoice => r.body)
    );
  }

  /** Path part for operation `invoicesGet()` */
  static readonly InvoicesGetPath = '/invoices';

  /**
   * List Invoices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesGet$Response(params?: InvoicesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Invoice>>> {
    return invoicesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * List Invoices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesGet(params?: InvoicesGet$Params, context?: HttpContext): Observable<Array<Invoice>> {
    return this.invoicesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Invoice>>): Array<Invoice> => r.body)
    );
  }

  /** Path part for operation `invoicesInvoiceIdGet()` */
  static readonly InvoicesInvoiceIdGetPath = '/invoices/{invoiceId}';

  /**
   * Get an invoice by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesInvoiceIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesInvoiceIdGet$Response(params: InvoicesInvoiceIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Invoice>> {
    return invoicesInvoiceIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an invoice by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesInvoiceIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesInvoiceIdGet(params: InvoicesInvoiceIdGet$Params, context?: HttpContext): Observable<Invoice> {
    return this.invoicesInvoiceIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Invoice>): Invoice => r.body)
    );
  }

  /** Path part for operation `invoicesInvoiceIdActionsGeneratePdfPost()` */
  static readonly InvoicesInvoiceIdActionsGeneratePdfPostPath = '/invoices/{invoiceId}/actions/generate-pdf';

  /**
   * Generate Invoice PDF.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invoicesInvoiceIdActionsGeneratePdfPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesInvoiceIdActionsGeneratePdfPost$Response(params: InvoicesInvoiceIdActionsGeneratePdfPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return invoicesInvoiceIdActionsGeneratePdfPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate Invoice PDF.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invoicesInvoiceIdActionsGeneratePdfPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  invoicesInvoiceIdActionsGeneratePdfPost(params: InvoicesInvoiceIdActionsGeneratePdfPost$Params, context?: HttpContext): Observable<Blob> {
    return this.invoicesInvoiceIdActionsGeneratePdfPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
