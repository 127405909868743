/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Project } from '../../models/project';
import { ProjectUpdateRequest } from '../../models/project-update-request';

export interface ProjectsProjectIdPut$Params {

/**
 * ID of the Project to update
 */
  projectId: string;
      body: ProjectUpdateRequest
}

export function projectsProjectIdPut(http: HttpClient, rootUrl: string, params: ProjectsProjectIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<Project>> {
  const rb = new RequestBuilder(rootUrl, projectsProjectIdPut.PATH, 'put');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Project>;
    })
  );
}

projectsProjectIdPut.PATH = '/projects/{projectId}';
