/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ActiveWorkLog } from '../models/active-work-log';
import { activeWorkLogsActiveWorkLogIdActionsStopPost } from '../fn/active-work-log/active-work-logs-active-work-log-id-actions-stop-post';
import { ActiveWorkLogsActiveWorkLogIdActionsStopPost$Params } from '../fn/active-work-log/active-work-logs-active-work-log-id-actions-stop-post';
import { activeWorkLogsActiveWorkLogIdDelete } from '../fn/active-work-log/active-work-logs-active-work-log-id-delete';
import { ActiveWorkLogsActiveWorkLogIdDelete$Params } from '../fn/active-work-log/active-work-logs-active-work-log-id-delete';
import { activeWorkLogsActiveWorkLogIdGet } from '../fn/active-work-log/active-work-logs-active-work-log-id-get';
import { ActiveWorkLogsActiveWorkLogIdGet$Params } from '../fn/active-work-log/active-work-logs-active-work-log-id-get';
import { activeWorkLogsActiveWorkLogIdPut } from '../fn/active-work-log/active-work-logs-active-work-log-id-put';
import { ActiveWorkLogsActiveWorkLogIdPut$Params } from '../fn/active-work-log/active-work-logs-active-work-log-id-put';
import { activeWorkLogsGet } from '../fn/active-work-log/active-work-logs-get';
import { ActiveWorkLogsGet$Params } from '../fn/active-work-log/active-work-logs-get';
import { activeWorkLogsPost } from '../fn/active-work-log/active-work-logs-post';
import { ActiveWorkLogsPost$Params } from '../fn/active-work-log/active-work-logs-post';
import { WorkLog } from '../models/work-log';

@Injectable({ providedIn: 'root' })
export class ActiveWorkLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activeWorkLogsGet()` */
  static readonly ActiveWorkLogsGetPath = '/active-work-logs';

  /**
   * List the currently active work logs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorkLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsGet$Response(params?: ActiveWorkLogsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActiveWorkLog>>> {
    return activeWorkLogsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * List the currently active work logs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorkLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsGet(params?: ActiveWorkLogsGet$Params, context?: HttpContext): Observable<Array<ActiveWorkLog>> {
    return this.activeWorkLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ActiveWorkLog>>): Array<ActiveWorkLog> => r.body)
    );
  }

  /** Path part for operation `activeWorkLogsPost()` */
  static readonly ActiveWorkLogsPostPath = '/active-work-logs';

  /**
   * Create an active work log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorkLogsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorkLogsPost$Response(params: ActiveWorkLogsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveWorkLog>> {
    return activeWorkLogsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an active work log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorkLogsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorkLogsPost(params: ActiveWorkLogsPost$Params, context?: HttpContext): Observable<ActiveWorkLog> {
    return this.activeWorkLogsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveWorkLog>): ActiveWorkLog => r.body)
    );
  }

  /** Path part for operation `activeWorkLogsActiveWorkLogIdGet()` */
  static readonly ActiveWorkLogsActiveWorkLogIdGetPath = '/active-work-logs/{activeWorkLogId}';

  /**
   * Get the currently active work log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorkLogsActiveWorkLogIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsActiveWorkLogIdGet$Response(params: ActiveWorkLogsActiveWorkLogIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveWorkLog>> {
    return activeWorkLogsActiveWorkLogIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the currently active work log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorkLogsActiveWorkLogIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsActiveWorkLogIdGet(params: ActiveWorkLogsActiveWorkLogIdGet$Params, context?: HttpContext): Observable<ActiveWorkLog> {
    return this.activeWorkLogsActiveWorkLogIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveWorkLog>): ActiveWorkLog => r.body)
    );
  }

  /** Path part for operation `activeWorkLogsActiveWorkLogIdPut()` */
  static readonly ActiveWorkLogsActiveWorkLogIdPutPath = '/active-work-logs/{activeWorkLogId}';

  /**
   * Update Active Worklog.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorkLogsActiveWorkLogIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorkLogsActiveWorkLogIdPut$Response(params: ActiveWorkLogsActiveWorkLogIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveWorkLog>> {
    return activeWorkLogsActiveWorkLogIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Active Worklog.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorkLogsActiveWorkLogIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activeWorkLogsActiveWorkLogIdPut(params: ActiveWorkLogsActiveWorkLogIdPut$Params, context?: HttpContext): Observable<ActiveWorkLog> {
    return this.activeWorkLogsActiveWorkLogIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActiveWorkLog>): ActiveWorkLog => r.body)
    );
  }

  /** Path part for operation `activeWorkLogsActiveWorkLogIdDelete()` */
  static readonly ActiveWorkLogsActiveWorkLogIdDeletePath = '/active-work-logs/{activeWorkLogId}';

  /**
   * Delete the Active Work Log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorkLogsActiveWorkLogIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsActiveWorkLogIdDelete$Response(params: ActiveWorkLogsActiveWorkLogIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return activeWorkLogsActiveWorkLogIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete the Active Work Log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorkLogsActiveWorkLogIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsActiveWorkLogIdDelete(params: ActiveWorkLogsActiveWorkLogIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.activeWorkLogsActiveWorkLogIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `activeWorkLogsActiveWorkLogIdActionsStopPost()` */
  static readonly ActiveWorkLogsActiveWorkLogIdActionsStopPostPath = '/active-work-logs/{activeWorkLogId}/actions/stop';

  /**
   * Stop the active work log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activeWorkLogsActiveWorkLogIdActionsStopPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsActiveWorkLogIdActionsStopPost$Response(params: ActiveWorkLogsActiveWorkLogIdActionsStopPost$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkLog>> {
    return activeWorkLogsActiveWorkLogIdActionsStopPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Stop the active work log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activeWorkLogsActiveWorkLogIdActionsStopPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activeWorkLogsActiveWorkLogIdActionsStopPost(params: ActiveWorkLogsActiveWorkLogIdActionsStopPost$Params, context?: HttpContext): Observable<WorkLog> {
    return this.activeWorkLogsActiveWorkLogIdActionsStopPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkLog>): WorkLog => r.body)
    );
  }

}
