import { Component } from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  title: string = 'Are you sure?';
  body: string = 'Are you sure?';
  confirmButtonText: string = 'Confirm';
  confirmButtonStatus: string = 'danger';
  onConfirm: () => void = undefined;
  confirmed: boolean = false;

  constructor(private dialogRef: NbDialogRef<any>) {
  }

  confirm() {
    this.confirmed = true;
    if (this.onConfirm)
      this.onConfirm();

    this.dismiss();
  }

  dismiss() {
    this.dialogRef.close();
  }
}
