<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    {{ body }}
  </nb-card-body>
  <nb-card-footer>
    <div class="fa-pull-right">
      <button class='cancel-button' disabled="{{confirmed}}" nbButton hero ghost status="basic" (click)="dismiss()">Cancel</button>
      <button nbButton disabled="{{confirmed}}" [nbSpinner]="confirmed" hero status="{{ confirmButtonStatus }}"
              (click)="confirm()">{{ confirmButtonText }}
      </button>
    </div>
  </nb-card-footer>
</nb-card>
