/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Client } from '../models/client';
import { clientsClientIdDelete } from '../fn/clients/clients-client-id-delete';
import { ClientsClientIdDelete$Params } from '../fn/clients/clients-client-id-delete';
import { clientsClientIdGet } from '../fn/clients/clients-client-id-get';
import { ClientsClientIdGet$Params } from '../fn/clients/clients-client-id-get';
import { clientsClientIdPut } from '../fn/clients/clients-client-id-put';
import { ClientsClientIdPut$Params } from '../fn/clients/clients-client-id-put';
import { clientsGet } from '../fn/clients/clients-get';
import { ClientsGet$Params } from '../fn/clients/clients-get';
import { clientsPost } from '../fn/clients/clients-post';
import { ClientsPost$Params } from '../fn/clients/clients-post';

@Injectable({ providedIn: 'root' })
export class ClientsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `clientsGet()` */
  static readonly ClientsGetPath = '/clients';

  /**
   * Retrieve all clients.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientsGet$Response(params?: ClientsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Client>>> {
    return clientsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all clients.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientsGet(params?: ClientsGet$Params, context?: HttpContext): Observable<Array<Client>> {
    return this.clientsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Client>>): Array<Client> => r.body)
    );
  }

  /** Path part for operation `clientsPost()` */
  static readonly ClientsPostPath = '/clients';

  /**
   * Create a client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientsPost$Response(params: ClientsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Client>> {
    return clientsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientsPost(params: ClientsPost$Params, context?: HttpContext): Observable<Client> {
    return this.clientsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Client>): Client => r.body)
    );
  }

  /** Path part for operation `clientsClientIdGet()` */
  static readonly ClientsClientIdGetPath = '/clients/{clientId}';

  /**
   * Retrieve a Client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientsClientIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientsClientIdGet$Response(params: ClientsClientIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Client>> {
    return clientsClientIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve a Client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientsClientIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientsClientIdGet(params: ClientsClientIdGet$Params, context?: HttpContext): Observable<Client> {
    return this.clientsClientIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Client>): Client => r.body)
    );
  }

  /** Path part for operation `clientsClientIdPut()` */
  static readonly ClientsClientIdPutPath = '/clients/{clientId}';

  /**
   * Update a Client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientsClientIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientsClientIdPut$Response(params: ClientsClientIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<Client>> {
    return clientsClientIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a Client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientsClientIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  clientsClientIdPut(params: ClientsClientIdPut$Params, context?: HttpContext): Observable<Client> {
    return this.clientsClientIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<Client>): Client => r.body)
    );
  }

  /** Path part for operation `clientsClientIdDelete()` */
  static readonly ClientsClientIdDeletePath = '/clients/{clientId}';

  /**
   * Delete a Client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientsClientIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientsClientIdDelete$Response(params: ClientsClientIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clientsClientIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a Client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientsClientIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  clientsClientIdDelete(params: ClientsClientIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.clientsClientIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
