/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Project } from '../models/project';
import { projectsGet } from '../fn/projects/projects-get';
import { ProjectsGet$Params } from '../fn/projects/projects-get';
import { projectsPost } from '../fn/projects/projects-post';
import { ProjectsPost$Params } from '../fn/projects/projects-post';
import { projectsProjectIdDelete } from '../fn/projects/projects-project-id-delete';
import { ProjectsProjectIdDelete$Params } from '../fn/projects/projects-project-id-delete';
import { projectsProjectIdGet } from '../fn/projects/projects-project-id-get';
import { ProjectsProjectIdGet$Params } from '../fn/projects/projects-project-id-get';
import { projectsProjectIdPut } from '../fn/projects/projects-project-id-put';
import { ProjectsProjectIdPut$Params } from '../fn/projects/projects-project-id-put';

@Injectable({ providedIn: 'root' })
export class ProjectsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectsGet()` */
  static readonly ProjectsGetPath = '/projects';

  /**
   * Retrieve all projects.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsGet$Response(params?: ProjectsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Project>>> {
    return projectsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all projects.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsGet(params?: ProjectsGet$Params, context?: HttpContext): Observable<Array<Project>> {
    return this.projectsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Project>>): Array<Project> => r.body)
    );
  }

  /** Path part for operation `projectsPost()` */
  static readonly ProjectsPostPath = '/projects';

  /**
   * Create a project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsPost$Response(params: ProjectsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Project>> {
    return projectsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsPost(params: ProjectsPost$Params, context?: HttpContext): Observable<Project> {
    return this.projectsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Project>): Project => r.body)
    );
  }

  /** Path part for operation `projectsProjectIdGet()` */
  static readonly ProjectsProjectIdGetPath = '/projects/{projectId}';

  /**
   * Retrieve a Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsProjectIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsProjectIdGet$Response(params: ProjectsProjectIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Project>> {
    return projectsProjectIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve a Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsProjectIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsProjectIdGet(params: ProjectsProjectIdGet$Params, context?: HttpContext): Observable<Project> {
    return this.projectsProjectIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Project>): Project => r.body)
    );
  }

  /** Path part for operation `projectsProjectIdPut()` */
  static readonly ProjectsProjectIdPutPath = '/projects/{projectId}';

  /**
   * Update a Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsProjectIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsProjectIdPut$Response(params: ProjectsProjectIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<Project>> {
    return projectsProjectIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsProjectIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsProjectIdPut(params: ProjectsProjectIdPut$Params, context?: HttpContext): Observable<Project> {
    return this.projectsProjectIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<Project>): Project => r.body)
    );
  }

  /** Path part for operation `projectsProjectIdDelete()` */
  static readonly ProjectsProjectIdDeletePath = '/projects/{projectId}';

  /**
   * Delete a Project.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsProjectIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsProjectIdDelete$Response(params: ProjectsProjectIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return projectsProjectIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a Project.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsProjectIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsProjectIdDelete(params: ProjectsProjectIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.projectsProjectIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
